<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Create User</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <UserForm @submitted="create" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import UserForm from '@/components/UserForm'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { UserForm, BaseBreadcrumb },
  setup() {
    const store = useStore()
    const toast = useToast()
    const router = useRouter()
    const breadcrumbs = [{ label: 'Users', to: '/users' }, { label: 'Create' }]

    function create(formData) {
      store
        .dispatch('createUser', formData)
        .then(() => {
          router.push({ name: 'UserIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Error creating user',
            detail: err.message,
            life: 3000
          })
        })
    }

    return { create, breadcrumbs }
  }
}
</script>
